import React from "react";
import { useNavigate } from "react-router-dom";

const Header = () => {
  const navigate = useNavigate();

  const handleNavigateToAbout = () => {
    navigate("/#about");
  };
  const handleNavigateToStarted = () => {
    navigate("/contact#head");
  };

  return (
    <div className="flex flex-col md:flex-row m-10 lg:m-20 xl:px-40 ">
      {/* 이미지 */}
      <img
        decoding="async"
        sizes="
          (min-width: 1200px) calc(max((max((100.3333vw - 260px) / 2, 1px) - 20px) / 2, 50px) * 2 + 20px),
          (min-width: 810px) and (max-width: 1199px) 380px,
          (max-width: 809px) calc(max((100.3333vw - 60px) / 2, 50px) * 2 + 20px)"
        srcSet="
          https://framerusercontent.com/images/m2CC1F1Qe094JBk7wuIgm2CeDk.png?scale-down-to=512 512w,
          https://framerusercontent.com/images/m2CC1F1Qe094JBk7wuIgm2CeDk.png 640w"
        src="../framerusercontent.com/images/m2CC1F1Qe094JBk7wuIgm2CeDk.png"
        alt=""
        className="block rounded object-center object-cover m-4 w-full md:w-auto"
      />

      {/* 텍스트와 버튼 */}
      <div className="flex flex-col justify-center m-4 md:m-8 text-center md:text-left">
        <h1 className="font-['League_Gothic'] text-4xl md:text-6xl font-light text-[#001f54] mb-4 md:mb[-2rem]">
          Fast, Accurate, and Secure
        </h1>
        <h1 className="font-['League_Gothic'] text-2xl md:text-4xl font-light text-[#001f54]">
          Transcription and Translation Services
        </h1>
        <div className="flex flex-col md:flex-row gap-4 mt-6 md:mt-8">
          <button onClick={handleNavigateToStarted} className="text-none">
            <div className="flex flex-col justify-start bg-[#001f54] w-full md:w-[10rem] rounded-full cursor-pointer">
              <p className="font-['Work_Sans'] text-white text-center m-4">
                Get Started
              </p>
            </div>
          </button>
          <button onClick={handleNavigateToAbout} className="text-none">
            <div className="flex flex-col justify-start bg-black w-full md:w-[10rem] rounded-full cursor-pointer">
              <p className="font-['Work_Sans'] text-white text-center m-4">
                Learn More
              </p>
            </div>
          </button>
        </div>
      </div>
    </div>
  );
};

export default Header;
